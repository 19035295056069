export const JsonSafe = {
    parse<T = Record<string, any>>(
        text: string | null,
        reviver?: (this: any, key: string, value: any) => any,
    ): T | null {
        try {
            return typeof text === 'string' ? JSON.parse(text, reviver) : null;
        } catch (error) {
            console.warn(error);
            return null;
        }
    },

    stringify(
        value: any,
        replacer?: (number | string)[] | null,
        space?: string | number,
    ): string {
        try {
            return JSON.stringify(value, replacer, space);
        } catch (error) {
            console.warn(error);
            return '';
        }
    },
};
