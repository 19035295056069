import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { UserTypeEnum } from 'reactApp/modules/CurrentUserModule/CurrentUserModule.types';
import { CurrentUserStore } from 'reactApp/modules/CurrentUserModule/CurrentUserStore';
import router from 'system/router';
import { CryptoHelper } from 'utils/CryptoHelper';

class CurrentUserStoreSelectorsClass {
    constructor() {
        makeAutoObservable(this, undefined, { autoBind: true });
    }

    get user() {
        return CurrentUserStore.user;
    }

    get email() {
        return this.user.email;
    }

    get phone() {
        return this.user.phone;
    }

    get allProjects() {
        return this.user.projects || [];
    }

    get allEnabledProjects() {
        return this.allProjects.filter((project) => project.enabled);
    }

    get projectsOSData() {
        return this.user.projectsOSData;
    }

    getProjectByProjectOSId(projectOsId: string | null) {
        const projectPid = Object.keys(this.projectsOSData).find(
            (key) => this.projectsOSData[key].os_project_id === projectOsId,
        );

        return this.allProjects.find((project) => project.pid === projectPid);
    }

    get partnerProjects() {
        return this.allEnabledProjects.filter((project) => project.is_partner);
    }

    get regularProjects() {
        return this.allEnabledProjects.filter((project) => !project.is_partner);
    }

    get currentAppProjects() {
        if (router.isPartnerApp()) {
            return this.partnerProjects;
        }
        return this.regularProjects;
    }

    get pid() {
        return this.user.selectedPid;
    }

    get hasEnabledProjectForSelectedPid() {
        return this.allEnabledProjects.some(
            (project) => project.pid === this.pid,
        );
    }

    getProject(pid: string) {
        return this.allEnabledProjects.find((project) => project.pid === pid);
    }

    get isPartner() {
        const project = this.getProject(this.pid);

        return project?.is_partner;
    }

    get currentProject() {
        return this.currentAppProjects.find(
            (project) => project.pid === this.pid,
        );
    }

    get roles() {
        return this.currentProject?.roles || [];
    }

    get isFederativeProject() {
        return Boolean(this.currentProject?.fed_exists);
    }

    get isFederativeUser() {
        return this.user.attr?.type === UserTypeEnum.fed;
    }

    get isNotFedUser() {
        return !this.isFederativeUser;
    }

    get isTfaEnabled() {
        return this.user.tfa;
    }

    get uid() {
        return this.user.uid;
    }

    get isTfaNotEnabled() {
        return !this.isTfaEnabled;
    }

    get isTfaRequired() {
        return this.currentProject?.tfa?.enabled && this.isNotFedUser;
    }

    get mustEnableTfa() {
        return this.isTfaRequired && this.isTfaNotEnabled;
    }

    get hasPartnerProject() {
        return this.partnerProjects.length > 0;
    }

    get hasAnyProject() {
        return this.allEnabledProjects.length > 0;
    }

    get verifiedObj() {
        return this.user.verified;
    }

    get isEmailVerified() {
        return Boolean(this.verifiedObj?.email);
    }

    get isPhoneVerified() {
        return Boolean(this.verifiedObj?.phone);
    }

    get emailUID() {
        return CryptoHelper.getInt36Fnv32aHash(this.user.email || '');
    }

    get isUserEnabled() {
        return Boolean(this.user.enabled);
    }

    get isUserDisabled() {
        return !this.isUserEnabled;
    }

    get canEarnBonuses() {
        return this.currentProject && !this.currentProject.skip_mcs_bonus;
    }

    get userProjectOSId() {
        return this.projectsOSData[this.pid]?.os_project_id;
    }

    get isAdminOfNetwork() {
        return (
            this.roles.length === 1 && this.roles.includes('mcs_admin_network')
        );
    }

    get ctime() {
        return this.user.ctime;
    }

    get signupMoment() {
        return moment((this.ctime || 0) * 1000);
    }

    get isNewUser() {
        return (
            this.ctime && new Date(this.ctime * 1000) > new Date('2020-09-28')
        );
    }

    get currentAppProjectsWithOwnerRole() {
        return this.currentAppProjects.filter((project) =>
            Boolean(
                project.roles.find(
                    (role) => role === 'mcs_owner' || role === 'mcs_co_owner',
                ),
            ),
        );
    }

    get signInUrl() {
        return this.user.signin_url;
    }
}

export const CurrentUserStoreSelectors = new CurrentUserStoreSelectorsClass();
