const AUTOTESTING_STORAGE_KEY = 'x-mcs-autotesting';
const AUTOTESTING_STORAGE_VALUE = '1';

const searchParams = new URL(window.location.href).searchParams || {};
let isAutoTesting =
    searchParams.get('utm_source') === 'Autotest' &&
    searchParams.get('utm_medium') === 'test';

if (isAutoTesting) {
    try {
        // Query-параметры могут поетряться при роутинге и редиректах,
        // поэтому запомним статус автотестирования.
        sessionStorage.setItem(
            AUTOTESTING_STORAGE_KEY,
            AUTOTESTING_STORAGE_VALUE,
        );
    } catch (reason) {
        // nothing to do
    }
} else {
    try {
        if (
            sessionStorage.getItem(AUTOTESTING_STORAGE_KEY) ===
            AUTOTESTING_STORAGE_VALUE
        ) {
            isAutoTesting = true;
        }
    } catch (reason) {
        // nothing to do
    }
}

let marketplaceBucket = searchParams.get('marketplace_bucket') || '';

const MARKETPLACE_BUCKET_KEY = 'x-mcs-marketplace-bucket';

if (marketplaceBucket) {
    try {
        // Query-параметры могут поетряться при роутинге и редиректах,
        // поэтому запомним статус автотестирования.
        sessionStorage.setItem(MARKETPLACE_BUCKET_KEY, marketplaceBucket);
    } catch (reason) {
        // nothing to do
    }
} else {
    try {
        const value = sessionStorage.getItem(MARKETPLACE_BUCKET_KEY);
        if (value) {
            marketplaceBucket = value;
        }
    } catch (reason) {
        // nothing to do
    }
}

export const TestParams = {
    isAutoTesting,
    marketplaceBucket,
};
