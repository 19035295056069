import * as RoleTypes from 'access/modules/RoleModule/RoleModule.types';
import * as ProjectTypes from 'reactApp/modules/ProjectModule/ProjectModule.types';
import * as WhiteLabelTypes from 'reactApp/modules/WhiteLabel/WhiteLabelTypes';

export type SignInSignUpData = {
    email: string;
    password: string;
    captcha?: string;
    phone?: string;
};

export type PartnerRequestData = {
    company: string;
    name: string;
    phone: string;
    promocode?: string;
};

export type VerifiedObj = {
    email: boolean;
    phone: boolean;
};
export type ProjectShortData = {
    pid: string;
    enabled: boolean;
    is_partner: boolean;
    title: string;
    white_label?: WhiteLabelTypes.WhiteLabelData;
    roles: RoleTypes.Role[];
    tfa?: {
        ctime: number;
        enabled: boolean;
        mtime: number;
        user: string;
    };
    skip_mcs_bonus?: boolean;
    fed_exists: boolean;
};
export type UserApiEntity = {
    email: string;

    tfa: boolean;

    verified: VerifiedObj;

    protected: boolean;

    name: string;
    phone: number | string;
    projects: ProjectShortData[];

    ctime?: number;
    domain?: string;
    enabled?: boolean;
    uid: number;
    signin_url?: string;
    attr: {
        company?: string;
        position?: string;
        type: UserTypeEnum;
    };
};

export type CurrentUser = UserApiEntity & {
    selectedPid: string;
    infraToken: string;
    projectsOSData: UsersProjectsOS;
};

export enum UserTypeEnum {
    fed = 'fed',
    mcs = 'mcs',
}

export type SignUpParams = { isPartner?: boolean };

export type EditUserParams = {
    name?: string;
    phone?: number | string;
    email?: string;
    company?: string;
    position?: string;
};

export type PasswordChangeApiParams = {
    password: string;
    new_password: string;
    captcha?: string;
};

export type PartnerSignupData = SignInSignUpData &
    ProjectTypes.PartnerProjectCreatingParams;

export type ProjectOS = {
    os_project_id: string;
};

export type UsersProjectsOS = Record<string, ProjectOS>;

export type PidsByUid = { [uid: number]: string };

export enum UserActions {
    signIn,
    signUp,
}
