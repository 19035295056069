import Cookies from 'js-cookie';
import { TestParams } from 'TestParams';
import { LangHelper } from 'utils/LangHelper';
import { UTM } from 'utils/UTM';

const { hostname, pathname, href } = window.location;

// Classification from https://en.wikipedia.org/wiki/Deployment_environment
const isProductionHost = /(^|\.)mcs\.mail\.ru$/.test(hostname);
const isStagingHost = /(^|\.)mcstest\.mail\.ru$/.test(hostname);
const isTestingHost = /(^|\.)mcs(\d?)\.devmail\.ru$/.test(hostname);
const isDevelopmentHost = /^local\./.test(hostname);
const isLandings = !/^\/(partner\/)?app\b/.test(pathname);
const isPrivateBuild = process.env.IS_PRIVATE_BUILD;
const isBeta = Cookies.get('mcsbeta') === '1';
const staticRoot = isDevelopmentHost ? '/static/' : '__%%STATIC_ROOT%%__';
const isAlterProd = Cookies.get('mcs_backend') === 'alter_prod';

type EnvId = 'prod' | 'stage' | 'test' | 'dev';
const envId: EnvId = isProductionHost
    ? 'prod'
    : isStagingHost
    ? 'stage'
    : isTestingHost
    ? 'test'
    : 'dev';

const isDevBuild = process.env.NODE_ENV === 'development';
const isProdBuild = process.env.NODE_ENV === 'production';

function getConfigurationUrl() {
    const filename = 'configuration.json';

    if (isDevelopmentHost) {
        if (process.env.WHITELABEL_NAME === 'X5') {
            return `https://x5.devmcs.ru/${filename}`;
        }
        if (process.env.WHITELABEL_NAME === 'greenatom') {
            return `https://greenatom.devmcs.ru/${filename}`;
        }
        if (process.env.WHITELABEL_NAME === 'megafon') {
            return `https://megafon.devmcs.ru/${filename}`;
        }
    }

    if (isProductionHost) {
        return `https://app.mcs.st/${filename}`;
    }

    if (isStagingHost || isTestingHost) {
        return `https://app.mcs.st/test/${filename}`;
    }

    return `/${filename}`;
}

export const Environment = {
    lang: LangHelper.getLangOnAppLoaded(isLandings),

    envId,

    isProductionHost,
    isStagingHost,
    isTestingHost,
    isDevelopmentHost,
    isDefaultDevelopmentHost: hostname === 'local.mcs.devmail.ru',
    isLandings,
    isPrivateBuild: Boolean(isPrivateBuild),
    isBeta,

    isAutoTesting: TestParams.isAutoTesting,
    marketplaceBucket: TestParams.marketplaceBucket,

    isProductionRun: !isDevelopmentHost && isProductionHost,
    isStagingRun: !isDevelopmentHost && isStagingHost,
    isTestingRun: !isDevelopmentHost && isTestingHost,
    isDevelopmentRun: isDevelopmentHost,

    configurationAppUrl: getConfigurationUrl(),

    utmParams: UTM.parse(href),

    isDevBuild,
    isProdBuild,
    staticRoot,

    isAlterProd,
};
