// @ts-ignore (7016) FIXME: Could not find a declaration file for module 'writ... Remove this comment to see the full error message
import wn from 'written-number';

// @ts-ignore (7006) FIXME: Parameter 'x' implicitly has an 'any' type.
function _sum(x, y) {
    // TODO: get it dynamically based on sum & max safe int lengths
    const MULTIPLIER = 100000;

    x = Math.floor(x * MULTIPLIER);
    y = Math.floor(y * MULTIPLIER);

    return (x + y) / MULTIPLIER;
}

function decimalSum(arr: number[]) {
    return arr.reduce((sum, num) => {
        return _sum(sum, num);
    }, 0);
}

function decimalSumWithFixed(arr: number[], fixed = 2) {
    return Number(NumberHelper.toFixedIfDecimal(decimalSum(arr), fixed));
}

const roundExtended = (number: number, fixed = 0) =>
    Math.round(number * 10 ** fixed) / 10 ** fixed;

function toFixedIfDecimal(n: number, length = 2) {
    if (n % 1 !== 0) {
        return n.toFixed(length);
    }
    return String(n);
}

type WrittenNumberOptions = {
    lang:
        | 'ar'
        | 'az'
        | 'en'
        | 'en-indian'
        | 'eo'
        | 'es'
        | 'fr'
        | 'hu'
        | 'id'
        | 'it'
        | 'pt'
        | 'pt-PT'
        | 'ru'
        | 'tr'
        | 'uk'
        | 'vi';
};
function writtenNumber(
    number: number,
    options: WrittenNumberOptions = { lang: 'ru' },
) {
    return wn(number, options).replace(/одинадцать/g, 'одиннадцать');
}

export const NumberHelper = {
    roundExtended,
    decimalSum,
    toFixedIfDecimal,
    writtenNumber,
    decimalSumWithFixed,
};
