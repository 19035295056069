import { makeAutoObservable } from 'mobx';
import {
    CurrentUser,
    UserTypeEnum,
} from 'reactApp/modules/CurrentUserModule/CurrentUserModule.types';

export const CURRENT_USER_INITIAL_STATE = {
    email: '',
    tfa: false,
    verified: {
        email: false,
        phone: false,
    },
    name: '',
    phone: 0,
    uid: 0,
    projects: [],
    projectsOSData: {},

    enabled: false,
    protected: false,

    attr: {
        position: '',
        company: '',
        type: UserTypeEnum.mcs,
    },

    selectedPid: '',
    infraToken: '',
};
class CurrentUserStoreClass {
    user: CurrentUser = CURRENT_USER_INITIAL_STATE;

    constructor() {
        makeAutoObservable(this, undefined, { autoBind: true });
    }
}

export const CurrentUserStore = new CurrentUserStoreClass();
