/**
 * Эмулирует интерфейс localStorage для работы в приватных вкладках
 * и других случаях, когда localStorage недоступно или выключено.
 */
export class FallbackStorage {
    _data: Record<string, string>;
    constructor() {
        this._data = Object.create(null);
    }

    getItem(key: string) {
        const data = this._data;

        if (key in data) {
            return data[key];
        }

        return null;
    }

    setItem(key: string, value: string) {
        if (arguments.length < 2) {
            throw new TypeError('2 arguments required');
        }

        this._data[key] = String(value);
    }

    removeItem(key: string) {
        delete this._data[key];
    }
}
