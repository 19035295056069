import * as R from 'ramda';
import { URLSearchParams } from 'url';
import { reflectStorage } from 'utils/ReflectStorage';

export type Lang = 'ru' | 'en';

const KNOWN_LANGS: Lang[] = ['ru', 'en'];
const DEFAULT_LANG: Lang = 'ru';

const HAS_LANG_IN_PATH_REGEX = new RegExp(
    `^((?:\\/partner)?\\/app)?\\/(${KNOWN_LANGS.join('|')})(\\/.*)?`,
);
const hasLangInPath = (path: string = window.location.pathname) =>
    HAS_LANG_IN_PATH_REGEX.test(path);
const getLangFromPath = (path: string = window.location.pathname) =>
    hasLangInPath(path)
        ? path.replace(HAS_LANG_IN_PATH_REGEX, '$2')
        : undefined;

const getLangOnAppLoaded = (isLandings: boolean) => {
    const { pathname, href } = window.location;

    // @ts-ignore
    const searchParams: URLSearchParams = new URL(href).searchParams || {};
    const langParam: Lang | null = searchParams.get('lang') as Lang;

    let searchParamsLang: Lang | null = null;

    if (langParam && KNOWN_LANGS.includes(langParam)) {
        searchParamsLang = langParam;
    }

    const pathLang = getLangFromPath(pathname);

    const lang: Lang =
        (searchParamsLang as Lang) ||
        pathLang ||
        (!isLandings && _getStoredLang()) ||
        DEFAULT_LANG;

    _storeLang(lang);

    return lang;
};

const LANG_INSERT_TO_PATH_REGEX = new RegExp(
    `((?:\\/partner)?\\/app)(\\/(?:${KNOWN_LANGS.join('|')}))?(\\/.*)?`,
);
const insertLangToPath = (path: string, lang: Lang) =>
    path.replace(LANG_INSERT_TO_PATH_REGEX, `$1/${lang}$3`);

type Route = { [key: string]: string | Route } | string;

// @ts-ignore (7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
const insertLangToRoute = (route: Route, lang: Lang) => {
    return R.mapObjIndexed<Route, Route>((value, key) => {
        if (key === 'url' && typeof value === 'string') {
            return insertLangToPath(value, lang);
        } else if (typeof value === 'object') {
            return insertLangToRoute(value, lang);
        }
        return value;
    }, route);
};

const LANGUAGE_FULL_NATIVE_NAMES: { [P in Lang]: string } = {
    ru: 'Русский',
    en: 'English',
};
const getLanguageFullNativeName = (lang: Lang) =>
    LANGUAGE_FULL_NATIVE_NAMES[lang];

const setLanguage = (lang: Lang) => {
    _storeLang(lang);
    const { pathname } = window.location;
    window.location.href = insertLangToPath(pathname, lang);
};

const _storeLang = (lang: Lang) => reflectStorage.setItem('lang', lang);

const _getStoredLang: () => Lang | undefined = () => {
    const lang = reflectStorage.getItem('lang');
    if (KNOWN_LANGS.includes(lang as any)) {
        return lang as Lang;
    }
    return undefined;
};

export const LangHelper = {
    insertLangToPath,
    hasLangInPath,
    getLangFromPath,
    getLangOnAppLoaded,
    insertLangToRoute,
    getLanguageFullNativeName,
    setLanguage,
    KNOWN_LANGS,
    DEFAULT_LANG,
};
