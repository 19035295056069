import { NumberHelper } from 'utils/NumberHelper';

// @ts-ignore (7006) FIXME: Parameter 'number' implicitly has an 'any' type.
function divideThousands(number, divider = ' ') {
    return `${number}`.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, `$1${divider}`);
}

type FormatNumOptions = {
    zeroToFree?: boolean;
    addRoubleSign?: boolean;
    toFixed?: number;
    toRound?: number;
    onlyDecimalToFixed?: number;
};
function formatNum(
    num: number | string,
    {
        zeroToFree,
        addRoubleSign,
        toFixed,
        toRound,
        onlyDecimalToFixed,
    }: FormatNumOptions = {},
) {
    num = Number(num);

    if (toRound) {
        num = NumberHelper.roundExtended(Number(num), toRound);
    }

    const isDecimal = num % 1 !== 0;

    if (
        toFixed !== undefined ||
        (onlyDecimalToFixed !== undefined && isDecimal)
    ) {
        num = Number(num).toFixed(toFixed || onlyDecimalToFixed);
    }

    num = divideThousands(num);

    if (zeroToFree && num === '0') {
        num = 'бесплатно';
    } else if (addRoubleSign) {
        num += ' ₽';
    }

    return num;
}

function stringToUint8Array(string: string) {
    const uint8Array = new Uint8Array(string.length);

    for (let i = 0, count = string.length; i < count; i++) {
        uint8Array[i] = string.charCodeAt(i);
    }

    return uint8Array;
}

function uint8ArrayToString(uint8Array: Uint8Array) {
    let result = '';

    if (typeof TextDecoder === 'undefined') {
        result = uint8ArrayToString(uint8Array);
    } else {
        result = new TextDecoder('utf-8').decode(uint8Array);
    }

    return result;
}

export const StringHelper = {
    /**
     * differences with padStart:
     * 'abc'.padStart(10, "foo") -> "foofoofabc"
     * stringHelper.padStartWithStrAsChar('abc', 10, 'foo') -> foofoofoofoofoofoofooabc
     */
    padStartWithStrAsChar: (text: string, minLength: number) => {
        let formattedText = `${text}`;
        let needSpaces = minLength - formattedText.length;

        while (needSpaces > 0) {
            formattedText = `\u2006 ${formattedText}`;
            needSpaces--;
        }

        return formattedText;
    },

    // @ts-ignore (7006) FIXME: Parameter 'string' implicitly has an 'any' type.
    cropToChar(string, char) {
        const charPosition = string.indexOf(char);
        return charPosition !== -1 ? string.slice(0, charPosition) : string;
    },

    stringToBool: (str?: string | null | boolean): boolean => {
        if (typeof str === 'string') {
            str = str.toLowerCase();
            if (str === 'false') {
                return false;
            } else if (str === 'true') {
                return true;
            }
            console.error('got unknown string as bool:', str);
            return Boolean(str);
        }
        return Boolean(str);
    },

    upperFirstLetter(str: string) {
        return str[0].toUpperCase() + str.slice(1);
    },

    sizeInBytes(string: string): number {
        return new TextEncoder().encode(string).length;
    },

    formatNum,
    stringToUint8Array,
    uint8ArrayToString,
};
