import { CurrentUserStoreSelectors } from 'reactApp/modules/CurrentUserModule/CurrentUserStoreSelectors';
import { FallbackStorage } from 'utilities/FallbackStorage';
import { JsonSafe } from 'utils/JsonSafe';

function getAvailableStorage() {
    try {
        // Может быть выключено в IE, переполнено
        // или недоступно в приватных вкладках.
        localStorage.setItem('reflectStorage', '1');
        localStorage.removeItem('reflectStorage');

        return localStorage;
    } catch (reason) {}

    return new FallbackStorage();
}

type KeyOptions = { withPid?: boolean; withEmailUID?: boolean };

/**
 * Позволяет работать с localStorage без необходимости
 * контролировать исключения и восстанавливать типы данных.
 */
class ReflectStorage {
    _storage: FallbackStorage | Storage;
    constructor() {
        this._storage = getAvailableStorage();
    }

    getItem(key: string) {
        try {
            return this._storage.getItem(key);
        } catch (reason) {}

        return null;
    }

    setItem(key: string, value: string) {
        try {
            this._storage.setItem(key, value);
        } catch (reason) {}
    }

    removeItem(key: string) {
        try {
            this._storage.removeItem(key);
        } catch (reason) {}
    }

    getHandledKey(
        // @ts-ignore (7006) FIXME: Parameter 'key' implicitly has an 'any' type.
        key,
        { withPid, withEmailUID = true }: KeyOptions = { withEmailUID: true },
    ) {
        if (withEmailUID) {
            const emailUid = CurrentUserStoreSelectors.emailUID;
            key = `${key}_${emailUid}`;
        }
        if (withPid) {
            const pid = CurrentUserStoreSelectors.pid;
            key = `${key}_${pid}`;
        }

        return key;
    }

    get<T = Record<string, any>>(key: string) {
        return JsonSafe.parse<T>(this._storage.getItem(key));
    }

    set(key: string, value: any) {
        try {
            this._storage.setItem(key, JSON.stringify(value));
        } catch (reason) {}
    }

    remove(key: string) {
        this.removeItem(key);
    }
}

export const reflectStorage = new ReflectStorage();
