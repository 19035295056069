/* eslint-disable */
// LEGACY
const RDASH = /-/g,
    RSPACE = /\s+/,
    r_camelCase = /-(.)/g,
    camelCase = function(_, chr) {
        return chr.toUpperCase();
    },
    hasOwn = {}.hasOwnProperty,
    emptyArray = [];
/**
 * Получить список слушателей
 * @param    {Object}  target
 * @param    {string}  name
 * @returns  {Array}
 * @memberOf Emitter
 */
function getListeners(target, name) {
    let list = target.__emList;

    name = name.toLowerCase().replace(RDASH, '');

    if (list === void 0) {
        list = target.__emList = {};
        list[name] = [];
    } else if (list[name] === void 0) {
        list[name] = [];
    }

    return list[name];
}

/**
 * Излучатель событий
 * @class Emitter
 * @constructs Emitter
 */
const Emitter = function() {};
Emitter.fn = Emitter.prototype = /** @lends Emitter# */ {
    constructor: Emitter,

    /**
     * Прикрепить обработчик для одного или нескольких событий, поддерживается `handleEvent`
     * @param   {string}    events  одно или несколько событий, разделенных пробелом
     * @param   {Function}  fn      функция обработчик
     * @returns {Emitter}
     */
    on(events, fn) {
        events = events.split(RSPACE);

        let n = events.length,
            list;

        while (n--) {
            list = getListeners(this, events[n]);
            list.push(fn);
        }

        return this;
    },

    /**
     * Удалить обработчик для одного или нескольких событий
     * @param   {string}    [events]  одно или несколько событий, разделенных пробелом
     * @param   {Function}  [fn]      функция обработчик, если не передать, будут отвязаны все обработчики
     * @returns {Emitter}
     */
    off(events, fn) {
        if (events === void 0) {
            this.__emList = events;
        } else {
            events = events.split(RSPACE);

            let n = events.length;

            while (n--) {
                let list = getListeners(this, events[n]),
                    i = list.length,
                    idx = -1;

                if (arguments.length === 1) {
                    list.splice(0, 1e5); // dirty hack
                } else {
                    if (list.indexOf) {
                        idx = list.indexOf(fn);
                    } else {
                        // old browsers
                        while (i--) {
                            /* istanbul ignore else */
                            if (list[i] === fn) {
                                idx = i;
                                break;
                            }
                        }
                    }

                    if (idx !== -1) {
                        list.splice(idx, 1);
                    }
                }
            }
        }

        return this;
    },

    /**
     * Прикрепить обработчик события, который выполняется единожды
     * @param   {string}    events  событие или список
     * @param   {Function}  fn      функция обработчик
     * @returns {Emitter}
     */
    one(events, fn) {
        var proxy = function() {
            this.off(events, proxy);
            return fn.apply(this, arguments);
        };

        return this.on(events, proxy);
    },

    /**
     * Распространить событие
     * @param   {string}  type    тип события
     * @param   {Array}   [args]  аргумент или массив аргументов
     * @returns {*}
     */
    emit(type, args) {
        let list = getListeners(this, type),
            i = list.length,
            fn,
            ctx,
            tmp,
            retVal,
            argsLength;

        type = 'on' + type.charAt(0).toUpperCase() + type.substr(1);

        if (type.includes('-')) {
            type = type.replace(r_camelCase, camelCase);
        }

        if (typeof this[type] === 'function') {
            retVal = this[type].apply(this, [].concat(args));
        }

        if (i > 0) {
            args = args === void 0 ? emptyArray : [].concat(args);
            argsLength = args.length;

            while (i--) {
                fn = list[i];
                ctx = this;

                /* istanbul ignore else */
                if (fn !== void 0) {
                    if (fn.handleEvent !== void 0) {
                        ctx = fn;
                        fn = fn.handleEvent;
                    }

                    if (argsLength === 0) {
                        tmp = fn.call(ctx);
                    } else if (argsLength === 1) {
                        tmp = fn.call(ctx, args[0]);
                    } else if (argsLength === 2) {
                        tmp = fn.call(ctx, args[0], args[1]);
                    } else {
                        tmp = fn.apply(ctx, args);
                    }

                    if (tmp !== void 0) {
                        retVal = tmp;
                    }
                }
            }
        }

        return retVal;
    },

    /**
     * Распространить `Emitter.Event`
     * @param   {string}  type    тип события
     * @param   {Array}   [args]  аргумент или массив аргументов
     * @param   {*}   [details]  детали объекта события
     * @returns {Emitter}
     */
    trigger(type, args, details) {
        const evt = new Event(type);

        evt.target = evt.target || this;
        evt.details = details;
        evt.result = this.emit(type.type || type, [evt].concat(args));

        return this;
    },
};

/**
 * Событие
 * @class Emitter.Event
 * @constructs Emitter.Event
 * @param   {string|Object|Event}  type  тип события
 * @returns {Emitter.Event}
 */
function Event(type) {
    if (type instanceof Event) {
        return type;
    }

    if (type.type) {
        for (const key in type) {
            /* istanbul ignore else */
            if (hasOwn.call(type, key)) {
                this[key] = type[key];
            }
        }

        type = type.type;
    }

    this.type = type.toLowerCase().replace(RDASH, '');
}

Event.fn = Event.prototype = /** @lends Emitter.Event# */ {
    constructor: Event,

    /**
     * Действие по умолчанию отмененом
     * @type {boolean}
     */
    defaultPrevented: false,

    /**
     * Вcплытие события отменено
     * @type {boolean}
     */
    propagationStopped: false,

    /**
     * Позволяет определить, было ли отменено действие по умолчанию
     * @returns {boolean}
     */
    isDefaultPrevented() {
        return this.defaultPrevented;
    },

    /**
     * Отменить действие по умолчанию
     */
    preventDefault() {
        this.defaultPrevented = true;
    },

    /**
     * Остановить продвижение события
     */
    stopPropagation() {
        this.propagationStopped = true;
    },

    /**
     * Позволяет определить, было ли отменено продвижение события
     * @return {boolean}
     */
    isPropagationStopped() {
        return this.propagationStopped;
    },
};

/**
 * Подмешать методы к объекту
 * @static
 * @memberof Emitter
 * @param   {Object}  target    цель
 * @returns {Object}
 */
Emitter.apply = function(target) {
    target.on = Emitter.fn.on;
    target.off = Emitter.fn.off;
    target.one = Emitter.fn.one;
    target.emit = Emitter.fn.emit;
    target.trigger = Emitter.fn.trigger;
    return target;
};

// exports
Emitter.Event = Event;
Emitter.getListeners = getListeners;

export default Emitter;
