const WELL_KNOWN_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'gclid'];
const MAX_VALUE_LENGTH = 512;

export const UTM = {
    parse(href: string) {
        const searchParams = new URL(href).searchParams;

        return WELL_KNOWN_PARAMS.reduce<StringMap<string>>(
            (params, key) => ({
                ...params,
                [key]: (searchParams.get(key) || '').slice(0, MAX_VALUE_LENGTH),
            }),
            {},
        );
    },
};
