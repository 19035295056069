import { CurrentUserStore } from 'reactApp/modules/CurrentUserModule/CurrentUserStore';
// @ts-ignore (1192) FIXME: Module '"/Users/da.petrov/projects/mcs/app/js/comp... Remove this comment to see the full error message
// eslint-disable-next-line import/default
import Pilot from 'system/libs/Pilot';

const router = {
    // @ts-ignore (7006) FIXME: Parameter 'routes' implicitly has an 'any' type.
    init(routes, target) {
        const pilot = Pilot.create(routes);

        pilot.listenFrom(target, {
            autoStart: true,
            // @ts-ignore (7006) FIXME: Parameter 'url' implicitly has an 'any' type.
            filter(url) {
                // Allow anchor navigation on pages
                if (url.includes('#')) {
                    return false;
                }

                const pathname = new Pilot.URL(url).pathname;
                return /^(\/partner)?\/app\//.test(pathname);
            },
        });

        this._proxy(pilot);
    },

    /**
     * Проксирует все методы и свойства инстанса pilot в объект router,
     * чтобы в остальных модулях использовать роутер следующим образом:
     *     var router = require('./router');
     *     router.go('#route-id');
     */
    // @ts-ignore (7006) FIXME: Parameter 'pilot' implicitly has an 'any' type.
    _proxy(pilot) {
        Object.setPrototypeOf(this, pilot);

        Object.keys(Pilot.prototype).forEach(function(key) {
            if (typeof Pilot.prototype[key] === 'function') {
                // @ts-ignore (2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
                this[key] = function() {
                    // eslint-disable-next-line prefer-spread,prefer-rest-params
                    return pilot[key].apply(pilot, arguments);
                };
            }
        }, this);
    },

    handleRouteParams(params: any = {}) {
        if (!params) {
            params = {};
        }
        params.currentpid =
            params.currentpid ||
            CurrentUserStore.user.selectedPid ||
            // when user has no project
            'mcs0';
        return params;
    },

    // @ts-ignore (7006) FIXME: Parameter 'params' implicitly has an 'any' type.
    redirectTo(routeId: string, params?, query?, details?) {
        // @ts-ignore (2339) FIXME: Property 'go' does not exist on type '{ init(route... Remove this comment to see the full error message
        this.go(routeId, params, query, {
            ...details,
            replaceState: true,
        });
    },

    // @ts-ignore (7006) FIXME: Parameter 'routeId' implicitly has an 'any' type.
    getRoute(routeId) {
        // @ts-ignore (2339) FIXME: Property 'routes' does not exist on type '{ init(r... Remove this comment to see the full error message
        return this.routes.find((route) => {
            return route.id === routeId;
        });
    },

    // @ts-ignore (7006) FIXME: Parameter 'url' implicitly has an 'any' type.
    isPartnerAppUrl(url) {
        return String(url).startsWith('/partner/app');
    },

    // @ts-ignore (7006) FIXME: Parameter 'request' implicitly has an 'any' type.
    isPartnerAppRequest(request) {
        return request && this.isPartnerAppUrl(request.path);
    },

    // @ts-ignore (7006) FIXME: Parameter 'route' implicitly has an 'any' type.
    isPartnerAppRoute(route) {
        return (
            route &&
            route.url &&
            route.url.pattern &&
            this.isPartnerAppUrl(route.url.pattern)
        );
    },

    isPartnerApp() {
        return (
            // @ts-ignore (2339) FIXME: Property 'activeRoute' does not exist on type '{ i... Remove this comment to see the full error message
            this.isPartnerAppRoute(this.activeRoute) ||
            // @ts-ignore (2339) FIXME: Property 'activeRequest' does not exist on type '{... Remove this comment to see the full error message
            this.isPartnerAppRequest(this.activeRequest)
        );
    },

    changePid(pid: string) {
        const query = new URLSearchParams(window.location.search);
        query.set('changedPid', 'true');

        window.location.href = `${
            window.location.origin
            // @ts-ignore (2339) FIXME: Property 'activeRoute' does not exist on type '{ i... Remove this comment to see the full error message
        }${this.activeRoute.url.pattern
            .replace(':currentpid', pid)
            .replace(/:.+$/, '')}?${query.toString()}`;
    },

    // @ts-ignore (7023) FIXME: 'isAfterPidChange' implicitly has return type 'any... Remove this comment to see the full error message
    isAfterPidChange() {
        // @ts-ignore (2339) FIXME: Property 'activeRequest' does not exist on type '{... Remove this comment to see the full error message
        return Boolean(this.activeRequest.query.changedPid);
    },
};

const pilotGetUrl = Pilot.prototype.getUrl;
// @ts-ignore (7006) FIXME: Parameter 'id' implicitly has an 'any' type.
Pilot.prototype.getUrl = function(id, params, query) {
    return pilotGetUrl.call(this, id, router.handleRouteParams(params), query);
};

const routeGetUrl = Pilot.Route.prototype.getUrl;
// @ts-ignore (7006) FIXME: Parameter 'params' implicitly has an 'any' type.
Pilot.Route.prototype.getUrl = function(params, query) {
    return routeGetUrl.call(this, router.handleRouteParams(params), query);
};

export default router as typeof router & {
    go(id: string, params?: any, query?: any, details?: any): void;
    // @ts-ignore (7006) FIXME: Parameter 'href' implicitly has an 'any' type.
    nav(href, details?, forceReload?): void;
    // @ts-ignore (7006) FIXME: Parameter 'params' implicitly has an 'any' type.
    reload(params, query?, details?): void;
    getUrl(id: string, params?: any, query?: any): string;
    activeRequest: any;
    activeRoute: any;
    routes: any[];
    on(action: string, callback: (...args: any[]) => any): any;
};
